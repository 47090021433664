import { getProjectIdFromURL, getSubSectionFromURL, MainRoutesForProject } from '../utils/navigation';
//project
import projectLabels from '../../project/common/viewTypesLabels';
import projectRoutes from '../../project/common/viewTypesRoutes';
import * as projectViews from '../../project/common/viewTypes';
//analysis
import analysisLabels from '../../analysis/viewTypesLabels';
import analysisRoutes from '../../analysis/viewTypesRoutes';
import * as analysisViews from '../../analysis/viewTypes';
//distribute
import distributionLabels from '../../campaign/common/viewTypesLabels';
import distributionRoutes from '../../campaign/common/viewTypesRoutes';
import * as distributionViews from '../../campaign/common/viewTypes';
import { isSightXPanelCampaign } from '../../campaign/helpers';
import { getSelectedCampaign, getSelectedCampaignId } from '../../../selectors/distribute/campaigns';
import {
  getAccountAccessType,
  getCurrentProTrial,
  getIsLiteAccount,
  getIsProfessionalAccount,
  getIsTrialAccount,
  getUserType,
} from '../../../selectors/common/user';
import { UserTypes } from '../../../interfaces/ISecurity';
import { NEW_CAMPAIGN_ID } from '../../campaign/common/constants';
import { isApollo, isNetquest } from '../../../services/platform';
import {
  FeatureStatus,
  isFeatureAvailable,
  isFeatureDisabled,
  isFeatureHidden,
} from '../../../common/accessControl.helper';
import {
  getAccountTypeWhereFeatureIsAvailable,
  getAddUserStatus,
  getAnalysisTurfStatus,
  getAutomatedPersonaStatus,
  getBasePanelsStatus,
  getBaseQuotasStatus,
  getCatalogStatus,
  getCustomizationStatus,
  getEmailSmsStatus,
  getFeatureNameForOptionString,
  getLanguagesStatus,
  getManualPersonaStatus,
  getPivotTableStatus,
  getSalesforceStatus,
  getTrendStatus,
  hasEducationalModal,
  isFeatureAvailableByAccountTier,
  wasProjectCreatedBeforeTrial,
} from '../../../selectors/accessControl';
import { isSupportUser, userHasTemplateRole } from '../../../selectors/security/user';
import { IGlobalState } from '../../../reducers/interfaces/state';
import { getProject, getProjectId } from '../../../selectors/activeProject/project';
import { getOnePageForBuild } from '../../../selectors/activeProject/onePageForBuild';
import { isConceptTest, isTextEntry } from '../utils/helpers';
import { isTurfSimulatorAllowedFor } from '../../../featureToggles/turfSimulator';
import { getLastResponsesRecreationDate, getViewType } from '../../../selectors/analysis/project';
import { Features, isFeatureEnabled } from '../../../featureToggles/features';
import { UpgradeTooltipText } from '../accessControl';
import { isOnTemplatesFolder } from '../../../selectors/currentProjectFolder/currentProjectFolder';
import { getConjoint2Questions } from '../../../selectors/analysis/conjointSimulator';

import { ILeftMenuItem, ILeftMenuSubOption } from './interface';

const isTrialsOfTiersEnv = isFeatureEnabled(Features.TrialsOfTiers);

const getLabelAndRoute = (
  labels: Record<string, string>,
  routes: Record<string, string>,
  key: string,
  initialRoute: string,
) => ({
  label: labels[key],
  route: initialRoute + routes[key],
  active: getSubSectionFromURL() === routes[key],
});

interface IProjectParams {
  customizationStatus: FeatureStatus;
  catalogStatus: FeatureStatus;
  languageStatus: FeatureStatus;
  addUserStatus: FeatureStatus;
  isInsideTemplatesFolder?: boolean;
  hasTemplateRole?: boolean;
}

const getOptionsForProject = (
  {
    customizationStatus,
    catalogStatus,
    languageStatus,
    addUserStatus,
    isInsideTemplatesFolder,
    hasTemplateRole,
  }: IProjectParams,
): ILeftMenuItem[] => {
  const getLabelAndRouteProject = (key: string) => getLabelAndRoute(
    projectLabels,
    projectRoutes,
    key,
    `/${MainRoutesForProject.project}/${getProjectIdFromURL()}/`,
  )!;

  const routes: ILeftMenuItem[] = [{
    ...getLabelAndRouteProject(projectViews.BUILD),
    key: projectViews.BUILD,
  }];

  if (!isFeatureHidden(catalogStatus)) {
    routes.push({
      ...getLabelAndRouteProject(projectViews.CATALOG),
      needUpgrade: isFeatureDisabled(catalogStatus),
      key: projectViews.CATALOG,
    });
  }

  routes.push({
    ...getLabelAndRouteProject(projectViews.SKIP_LOGIC),
    key: projectViews.SKIP_LOGIC,
  });

  if (!isFeatureHidden(customizationStatus)) {
    routes.push({
      ...getLabelAndRouteProject(projectViews.CUSTOMIZE),
      key: projectViews.CUSTOMIZE,
    });
  }

  if (!isFeatureHidden(languageStatus)) {
    routes.push({
      ...getLabelAndRouteProject(projectViews.LANGUAGES),
      needUpgrade: isFeatureDisabled(languageStatus),
      key: projectViews.LANGUAGES,
    });
  }

  routes.push({
    ...getLabelAndRouteProject(projectViews.OPTIONS),
    key: projectViews.OPTIONS,
  });

  if (!isFeatureHidden(addUserStatus)) {
    routes.push({
      ...getLabelAndRouteProject(projectViews.USERS),
      key: projectViews.USERS,
      needUpgrade: isFeatureDisabled(addUserStatus),
    });
  }

  if (isInsideTemplatesFolder && hasTemplateRole) {
    routes.push({
      ...getLabelAndRouteProject(projectViews.TEMPLATE_TAGS),
      key: projectViews.TEMPLATE_TAGS,
      needUpgrade: false,
    });
  }

  return routes;
};

interface IOptionsForAnalysisParams {
  hasConceptTest: boolean;
  hasTextEntry: boolean;
  isTurfAvailable: boolean;
  showTrend: boolean;
  pivotTableStatus: FeatureStatus;
  manualPersonaStatus: FeatureStatus;
  automatedPersonaStatus: FeatureStatus;
  turfAnalysisStatus: FeatureStatus;
  isLiteAccount: boolean;
  isProfessionalAccount: boolean;
  hasProTrial: boolean;
  isProjectPreviousTrial: boolean;
  accessType: string;
  isConjointAvailable: boolean;
  viewType: string;
}

const getLabelAndRouteAnalysis = (key: string) => getLabelAndRoute(
  analysisLabels,
  analysisRoutes,
  key,
  `/${MainRoutesForProject.analysis}/${getProjectIdFromURL()}/`,
)!;

const getPermissionsForOption = (
  optionStatus: FeatureStatus,
  analysisViewKey: string,
  accessType: string,
  isProjectPreviousTrial: boolean,
  hasProTrial: boolean,
): ILeftMenuSubOption[] => {
  if (isFeatureHidden(optionStatus)) {
    return [];
  }

  const featureName = getFeatureNameForOptionString(analysisViewKey);
  const isDisabled = isFeatureDisabled(optionStatus);
  const optionDetails: ILeftMenuItem = {
    ...getLabelAndRouteAnalysis(analysisViewKey),
    key: analysisViewKey,
    needUpgrade: isDisabled,
    disabledText: UpgradeTooltipText,
  };

  // TODO: Check how we show remove this FT
  if (!isTrialsOfTiersEnv || featureName === 'other' || !isDisabled) {
    return [optionDetails];
  }

  const shouldDisplayModal = hasEducationalModal(featureName);
  const accountType = getAccountTypeWhereFeatureIsAvailable(featureName, accessType);
  if (!accountType) {
    return [];
  }

  if ((shouldDisplayModal && isDisabled)) {
    return [
      {
        ...optionDetails,
        needUpgrade: false,
        allowTestTrial: true,
        featureName,
      },
    ];
  }

  if ((hasProTrial && isDisabled && isProjectPreviousTrial) ||
    (!isFeatureAvailableByAccountTier(featureName, accessType) && !isDisabled)) {
    return [
      {
        ...optionDetails,
        needUpgrade: true,
        allowTestTrial: false,
      },
    ];
  }

  return [];
};

const getOptionsForAnalysis = ({
  showTrend,
  hasConceptTest,
  hasTextEntry,
  pivotTableStatus,
  isTurfAvailable,
  manualPersonaStatus,
  automatedPersonaStatus,
  turfAnalysisStatus,
  isLiteAccount,
  isProfessionalAccount,
  hasProTrial,
  isProjectPreviousTrial,
  accessType,
  isConjointAvailable,
  viewType,
}: IOptionsForAnalysisParams): ILeftMenuItem[] => {
  const pivotTableOption: ILeftMenuSubOption[] = getPermissionsForOption(pivotTableStatus, analysisViews.PIVOT_TABLES_DASHBOARD, accessType, isProjectPreviousTrial, hasProTrial);

  const advancedStatsOption: ILeftMenuItem = {
    label: 'Advanced Stats',
    key: 'ADVANCED_STATS',
    needUpgrade: isLiteAccount || isProfessionalAccount,
    disabledText: UpgradeTooltipText,
    active: [
      analysisRoutes[analysisViews.CORRELATION],
      analysisRoutes[analysisViews.LINEAR_REGRESSION],
      analysisRoutes[analysisViews.TREND],
    ].includes(getSubSectionFromURL()),
    subOptions: [
      {
        ...getLabelAndRouteAnalysis(analysisViews.CORRELATION),
        key: analysisViews.CORRELATION,
      },
      {
        ...getLabelAndRouteAnalysis(analysisViews.LINEAR_REGRESSION),
        key: analysisViews.LINEAR_REGRESSION,
      },
    ],
  };

  const routes: ILeftMenuItem[] = [
    {
      ...getLabelAndRouteAnalysis(analysisViews.OVERVIEW),
      key: analysisViews.OVERVIEW,
    },
    {
      ...getLabelAndRouteAnalysis(analysisViews.RESPONSES),
      key: analysisViews.RESPONSES,
    },
    {
      ...getLabelAndRouteAnalysis(analysisViews.ANALYSIS),
      key: analysisViews.ANALYSIS,
    },
    ...pivotTableOption,
    advancedStatsOption,
  ];

  const advStatsRoute = routes[routes.length - 1];

  if (showTrend && 'subOptions' in advStatsRoute) {
    advStatsRoute.subOptions.push({
      ...getLabelAndRouteAnalysis(analysisViews.TREND),
      key: analysisViews.TREND,
    });
  }

  if (isTurfAvailable && !isFeatureEnabled(Features.conjoint)) {
    const turfRoute: ILeftMenuSubOption[] = getPermissionsForOption(turfAnalysisStatus, analysisViews.TURF_SIMULATOR, accessType, isProjectPreviousTrial, hasProTrial);
    routes.push(...turfRoute);
  }

  if (isFeatureEnabled(Features.conjoint)) {
    const subOptions: ILeftMenuSubOption[] = [];
    if (isTurfAvailable) {
      const turfRoute: ILeftMenuSubOption[] = getPermissionsForOption(turfAnalysisStatus, analysisViews.TURF_SIMULATOR, accessType, isProjectPreviousTrial, hasProTrial);
      subOptions.push(...turfRoute);
    }
    if (isConjointAvailable) {
      subOptions.push({
        ...getLabelAndRouteAnalysis(analysisViews.CONJOINT_SIMULATOR),
        key: analysisViews.CONJOINT_SIMULATOR,
      });
    }

    if (subOptions.length > 0) {
      routes.push({
        label: 'Simulators',
        key: 'SIMULATORS_SUBMENU',
        active: (['turf', 'conjoint'].includes(getSubSectionFromURL()) || viewType === analysisViews.CONJOINT_SEGMENTATION),
        subOptions,
      });
    }
  }

  if (hasConceptTest) {
    routes.push({
      ...getLabelAndRouteAnalysis(analysisViews.CONCEPT_TEST),
      key: analysisViews.CONCEPT_TEST,
    });
  }

  const manualPersonasOptionDetails: ILeftMenuSubOption[] = getPermissionsForOption(manualPersonaStatus, analysisViews.PERSONAS, accessType, isProjectPreviousTrial, hasProTrial);
  const automatedPersonasOptionDetails: ILeftMenuSubOption[] = getPermissionsForOption(automatedPersonaStatus, analysisViews.UNSUPERVISED_SEGMENTATION, accessType, isProjectPreviousTrial, hasProTrial);
  const subOptionsForPersonas: ILeftMenuSubOption[] = [...manualPersonasOptionDetails, ...automatedPersonasOptionDetails];

  routes.push({
    label: 'Personas',
    key: 'PERSONAS_SUBMENU',
    active: ([
      analysisRoutes[analysisViews.PERSONAS],
      analysisRoutes[analysisViews.UNSUPERVISED_SEGMENTATION],
    ].includes(getSubSectionFromURL()) && viewType !== analysisViews.CONJOINT_SEGMENTATION),
    // TODO: Check how we show remove this FT
    needUpgrade: isTrialsOfTiersEnv ? false : !isFeatureAvailable(manualPersonaStatus) && !isFeatureAvailable(automatedPersonaStatus),
    subOptions: subOptionsForPersonas,
  });

  if (hasTextEntry && !isNetquest()) {
    routes.push({
      ...getLabelAndRouteAnalysis(analysisViews.TEXT_DISTRIBUTION),
      key: analysisViews.TEXT_DISTRIBUTION,
    });
  }

  return routes;
};

interface IDistributionParams {
  campaignSelected: string;
  isEmailSmsDisabled: boolean;
  isEmailSmsHidden: boolean;
  isSalesforceAvailable: boolean;
  isTrialAccount: boolean;
  showJWTTest: boolean;
  quotasStatus: FeatureStatus;
  panelStatus: FeatureStatus;
}

const getOptionsForDistribution = (
  {
    campaignSelected,
    isEmailSmsDisabled,
    isEmailSmsHidden,
    isSalesforceAvailable,
    isTrialAccount,
    showJWTTest,
    quotasStatus,
    panelStatus,
  }: IDistributionParams,
): ILeftMenuItem[] => {
  const getLabelAndRouteDistribution = (key: string) => getLabelAndRoute(
    distributionLabels,
    distributionRoutes,
    key,
    `/${MainRoutesForProject.distribute}/${getProjectIdFromURL()}/campaigns/${campaignSelected}/`,
  )!;
  const upgradeMsg = 'Upgrade to a subscription account to access this feature';

  const routesOptions: ILeftMenuItem[] = [{
    ...getLabelAndRouteDistribution(distributionViews.OVERVIEW),
    key: distributionViews.OVERVIEW,
  }];

  routesOptions.push({
    ...getLabelAndRouteDistribution(distributionViews.SETTINGS),
    key: distributionViews.SETTINGS,
  });

  routesOptions.push({
    ...getLabelAndRouteDistribution(distributionViews.SHARE),
    key: distributionViews.SHARE,
  });

  if (!isFeatureHidden(panelStatus)) {
    routesOptions.push({
      ...getLabelAndRouteDistribution(distributionViews.PANEL),
      needUpgrade: isTrialAccount || (isFeatureDisabled(panelStatus)),
      disabledText: isTrialAccount ? upgradeMsg : UpgradeTooltipText,
      key: distributionViews.PANEL,
    });
  }

  if (!isFeatureHidden(quotasStatus)) {
    routesOptions.push({
      ...getLabelAndRouteDistribution(distributionViews.QUOTAS),
      needUpgrade: isFeatureDisabled(quotasStatus),
      key: distributionViews.QUOTAS,
    });
  }

  if (showJWTTest) {
    routesOptions.push({
      ...getLabelAndRouteDistribution(distributionViews.JWT_TEST),
      key: distributionViews.JWT_TEST,
    });
  }

  if (isSalesforceAvailable) {
    routesOptions.push({
      ...getLabelAndRouteDistribution(distributionViews.DATA_SYNC),
      key: distributionViews.DATA_SYNC,
    });
  }

  if (!isEmailSmsHidden) {
    const emailSmsOption: ILeftMenuItem = {
      ...getLabelAndRouteDistribution(distributionViews.SEND),
      key: distributionViews.SEND,
      needUpgrade: isTrialAccount || isEmailSmsDisabled,
      disabledText: isTrialAccount ? upgradeMsg : UpgradeTooltipText,
    };

    routesOptions.splice(3, 0, emailSmsOption);
  }

  return routesOptions;
};

const getOptionsForPanel = (campaignSelected: string, isSightxAdmin: boolean, isSupport: boolean): ILeftMenuItem[] => {
  const isNewCampaign = campaignSelected === NEW_CAMPAIGN_ID;
  const getLabelAndRouteDistribution = (key: string) => getLabelAndRoute(
    distributionLabels,
    distributionRoutes,
    key,
    `/${MainRoutesForProject.distribute}/${getProjectIdFromURL()}/campaigns/${campaignSelected}/`,
  )!;

  const adminOption: ILeftMenuItem[] = isSightxAdmin ? [{
    ...getLabelAndRouteDistribution(distributionViews.ADMIN),
    needUpgrade: isNewCampaign,
    key: distributionViews.ADMIN,
  }] : [];

  const settingsOption: ILeftMenuItem[] = isSupport ? [{
    ...getLabelAndRouteDistribution(distributionViews.SETTINGS),
    key: distributionViews.SETTINGS,
  }] : [];

  return [
    {
      ...getLabelAndRouteDistribution(distributionViews.PANEL_AUDIENCE_SAMPLE),
      needUpgrade: isNewCampaign,
      key: distributionViews.PANEL_AUDIENCE_SAMPLE,
    },
    ...adminOption,
    ...settingsOption,
  ];
};

export const getOptionsByDashboard = (mainRoute: MainRoutesForProject) => (state: IGlobalState) => {
  if (mainRoute === MainRoutesForProject.project) {
    return getOptionsForProject({
      customizationStatus: getCustomizationStatus(state),
      catalogStatus: getCatalogStatus(state),
      languageStatus: getLanguagesStatus(state),
      addUserStatus: getAddUserStatus(state),
      isInsideTemplatesFolder: isOnTemplatesFolder(state),
      hasTemplateRole: userHasTemplateRole(state),
    });
  }

  if (mainRoute === MainRoutesForProject.analysis) {
    const isTrendAvailable = isFeatureAvailable(getTrendStatus(state));
    const hasConceptTest = getOnePageForBuild(state).some((item) => isConceptTest(item));
    const hasTextEntry = getOnePageForBuild(state).some((item) => isTextEntry(item));
    const projectId = getProjectId(state);
    const lastResponsesRecreationDate = getLastResponsesRecreationDate(projectId)(state);
    const pivotTableStatus = getPivotTableStatus(state);
    const turfAnalysisStatus = getAnalysisTurfStatus(state);
    const isProfessionalAccount = getIsProfessionalAccount(state);
    const isLiteAccount = getIsLiteAccount(state);
    const accessType = getAccountAccessType(state);
    const isConjointAvailable = getConjoint2Questions(state).length > 0;
    const viewType = getViewType(state);

    const hasProTrial = !!getCurrentProTrial(state);
    const isProjectPreviousTrial = wasProjectCreatedBeforeTrial(state);

    return getOptionsForAnalysis({
      manualPersonaStatus: getManualPersonaStatus(state),
      automatedPersonaStatus: getAutomatedPersonaStatus(state),
      hasConceptTest,
      hasTextEntry,
      pivotTableStatus,
      turfAnalysisStatus,
      isTurfAvailable: isTurfSimulatorAllowedFor({ date: lastResponsesRecreationDate }),
      showTrend: isTrendAvailable,
      isProfessionalAccount,
      isLiteAccount,
      hasProTrial,
      isProjectPreviousTrial,
      accessType,
      isConjointAvailable,
      viewType,
    });
  }

  const campaignSelected = getSelectedCampaignId(state);
  if (mainRoute === MainRoutesForProject.distribute && campaignSelected) {
    const { hasAttributeQuestions = false } = getProject(state);
    const campaign = getSelectedCampaign(state);
    const userType = getUserType(state);
    const showJWTTest = isSupportUser(state) && hasAttributeQuestions;
    const salesforceStatus = getSalesforceStatus(state);
    const isSalesforceAvailable =
      !isApollo() && isFeatureAvailable(salesforceStatus);
    const isTrialAccount = getIsTrialAccount(state);

    const emailSmsStatus = getEmailSmsStatus(state);
    const isEmailSmsHidden = emailSmsStatus === FeatureStatus.HIDDEN;
    const isEmailSmsDisabled = emailSmsStatus === FeatureStatus.DISABLED;
    const quotasStatus = getBaseQuotasStatus(state);
    let panelStatus = getBasePanelsStatus(state);
    if (
      isNetquest() && panelStatus === FeatureStatus.AVAILABLE && ![
        UserTypes.MAIN_ADMIN, UserTypes.ACCOUNT_ADMIN, UserTypes.SX_ADMIN, UserTypes.ADMIN_ASSISTANT,
      ].includes(userType)
    ) {
      panelStatus = FeatureStatus.HIDDEN;
    }
    const isSupport = isSupportUser(state);
    return isSightXPanelCampaign(campaign)
      ? getOptionsForPanel(campaignSelected, userType === UserTypes.SX_ADMIN, isSupport)
      : getOptionsForDistribution({
        campaignSelected,
        isEmailSmsDisabled,
        isEmailSmsHidden,
        isSalesforceAvailable,
        isTrialAccount,
        showJWTTest,
        quotasStatus,
        panelStatus,
      });
  }

  return [];
};
