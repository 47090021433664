import React, { memo, useState } from 'react';

import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import cx from 'classnames';

import { isLite, isNetquest } from '../../../services/platform';
import sightXLogo from '../../../assets/sightxLogoWhite.svg';
import sightXLogoLite from '../../../assets/sightxLogoLite.svg';
import { isBrandingPath, isFolderPath, isHomePath, isProjectsPath } from '../utils/navigation';
import { getPathname } from '../../../selectors/common/selectors';
import { AIConsultant } from '../../aiConsultant';
import { Features, isFeatureEnabled } from '../../../featureToggles/features';
import { getSectionSecurityPermissions } from '../../../reducers/security/selectors';
import { FEATURES, isFeatureAvailable, isFeatureHidden } from '../../../common/accessControl.helper';
import { EducationModalBase } from '../educationalModalBase';
import { getBrandingStatus } from '../../../selectors/accessControl';
import { BrandingRoutePath } from '../../../router.utils';
import { tooltipMessage } from '../../../data/sectionAccessPermissions';

import { UpgradeToProCTA } from './UpgradeToProButton';
import { ProjectMenu } from './ProjectMenu';
import { UserMenu } from './UserMenu';
import { Option } from './Option';
import { Logo } from './logo/Logo';

import styles from './index.module.scss';

const isTrialsEnvEnabled = isFeatureEnabled(Features.TrialsOfTiers);

export const LeftNavBar = memo(() => {
  const pathName = useSelector(getPathname);
  const sectionSecurityPermissions = useSelector(getSectionSecurityPermissions);
  const hasBuildAccess = sectionSecurityPermissions.build ?? false;
  const hasBrandingAccess = (isFeatureEnabled(Features.branding) && sectionSecurityPermissions.branding) ?? false;
  const isLiteEnv = isLite();
  const logo = isLiteEnv ? sightXLogoLite : sightXLogo;
  const [isEducationalModalOpen, setEducationalModalOpenState] = useState<boolean>(false);
  const brandingPolicyStatus = useSelector(getBrandingStatus);
  const isBrandingPolicyHidden = isFeatureHidden(brandingPolicyStatus);
  const isBrandingPolicyAvailable = isFeatureAvailable(brandingPolicyStatus);

  const closeEducationalModal = () => {
    setEducationalModalOpenState(false);
  };

  const renderHomeOption = () => {
    if (!hasBuildAccess) {
      return <Option optionClassName={styles.mainOption} key="MY_PROJECTS" icon="sx-survey" label="My Projects" route="/" active={isHomePath(pathName) || isProjectsPath(pathName) || isFolderPath(pathName)} />;
    }
    return <Option optionClassName={styles.mainOption} key="HOME" icon="sx-home" label="Home" route="/" active={isHomePath(pathName)} />;
  };

  const openEducationModal = (featureName: FEATURES) => {
    if (featureName !== FEATURES.BRANDING) {
      return null;
    }
    setEducationalModalOpenState(true);
  };

  const renderBrandingOption = () => {
    if (!isFeatureEnabled(Features.branding) || isBrandingPolicyHidden || isNetquest()) {
      return null;
    }
    return (
      <Option
        key="BRANDING"
        optionClassName={cx(styles.mainOption, styles.branding, { [styles.disabled]: isBrandingPolicyAvailable && !hasBrandingAccess })}
        icon="sx-paintbrush"
        label="branding"
        isBeta
        route={BrandingRoutePath}
        active={isBrandingPath(pathName)}
        allowTestTrial={!isBrandingPolicyAvailable}
        needUpgrade={!isBrandingPolicyAvailable}
        featureName={FEATURES.BRANDING}
        openEducationModalFor={openEducationModal}
        rightIconSize={14}
        disabled={!hasBrandingAccess}
        disabledText={tooltipMessage}
      />
    );
  };

  const renderMyProjects = () => {
    if (!hasBuildAccess) {
      return null;
    }
    return <Option optionClassName={styles.mainOption} key="MY_PROJECTS" icon="sx-survey" label="My Projects" route="/projects" active={isProjectsPath(pathName) || isFolderPath(pathName)} />;
  };

  const renderLogo = () => {
    if (isFeatureEnabled(Features.netquest)) {
      return <Logo />;
    }
    return <div className={cx(styles.logo, { [styles[Features.TrialsOfTiers]]: isTrialsEnvEnabled })} data-e2e-selector="sightx-logo">
      <Link to="/"><img src={logo} alt="SightX" /></Link>
    </div>;
  };

  const renderEducationalModal = () => {
    return isEducationalModalOpen && (
      <EducationModalBase
        onClose={closeEducationalModal}
        withBackButton={false}
        featureName={FEATURES.BRANDING}
        className={styles.educationalModalIcon}
        showCloseIcon
      />
    );
  };

  return <div className={cx(styles.leftNavigation, { [styles[Features.netquest]]: isFeatureEnabled(Features.netquest) })}>
    {renderLogo()}
    <div className={cx({ [styles.initialOptions]: isFeatureEnabled(Features.netquest) })}>
      { isLiteEnv && <UpgradeToProCTA/> }
      {renderHomeOption()}
      {renderMyProjects()}
      {renderBrandingOption()}
    </div>
    <ProjectMenu />
    <AIConsultant />
    <UserMenu />
    {renderEducationalModal()}
  </div>;
});
