import React, { Component } from 'react';

import { connect } from 'react-redux';
import cx from 'classnames';

import { IGlobalState } from '../../reducers/interfaces/state';
import { openChatWindow, closeChatWindow } from '../../reducers/gpt/aiConsultant';
import sightxAILogo from '../../assets/gifs/sightx-ai-logo-white-150.gif';
import aiNonAnimatedSxLogo from '../../assets/aiUserLogoForLight.svg';
import { fetchConversation } from '../../actions/aiConsultant';
import { getGPTStatus } from '../../selectors/accessControl';
import { FeatureStatus } from '../../interfaces/ISecurity';
import { isFeatureAvailable, isFeatureDisabled, isFeatureHidden } from '../../common/accessControl.helper';
import { Tooltip } from '../common/utils/wrapComponents';

import styles from './aiConsultant.module.scss';

const DEFAULT_INITIAL_HEIGHT = 314;
const INITIAL_HEIGHT_KEY = 'aiHeight';

interface IAIConsultantMapsProps {
  opened: boolean;
  gptStatus: FeatureStatus;
}

interface IDispatchProps {
  fetchConversation: typeof fetchConversation;
  openChatWindow: typeof openChatWindow;
  closeChatWindow: typeof closeChatWindow;
}

type Props = IAIConsultantMapsProps & IDispatchProps;

class AIConsultantBase extends Component<Props> {
  messagesRefs: React.RefObject<HTMLDivElement>[];
  constructor(props) {
    super(props);
    const value: string | null = localStorage.getItem(INITIAL_HEIGHT_KEY);
    const initialHeight = Number(value ?? DEFAULT_INITIAL_HEIGHT);
    this.state = {
      opened: false,
      popularTopicsOpened: true,
      message: '',
      initialHeight,
    };
    this.onToggleChat = this.onToggleChat.bind(this);
    this.messagesRefs = [];
  }

  private onToggleChat() {
    const { opened, gptStatus } = this.props;
    if (!isFeatureAvailable(gptStatus)) {
      return;
    }
    if (opened) {
      this.props.closeChatWindow();
      return;
    }
    this.props.fetchConversation();
    this.props.openChatWindow();
  }

  render() {
    const { gptStatus, opened } = this.props;
    if (isFeatureHidden(gptStatus)) {
      return null;
    }

    const logo = opened ? aiNonAnimatedSxLogo : sightxAILogo;

    const button = <span className={styles.openChatButton} onClick={this.onToggleChat}>
      <span className={styles.buttonInnerContainer}>
        <img className={cx(styles.icon, { [styles.opened]: opened })} src={logo} alt="sightx ai" />
        <span className={styles.text}>
          <span className={styles.innerText}>Hi, I'm Ada</span>
        </span>
      </span>
    </span>;

    return isFeatureDisabled(gptStatus) ?
      <Tooltip
        classes={[styles.tooltip]}
        Component={button}
        trigger={['click']}
        placement="right"
        tooltipText={<span>Contact <a href="mailto:support@sightx.io">support@sightx.io</a> to enable this feature</span>}
      />
      : button;
  }
}

const mapStateToProps = (state: IGlobalState): IAIConsultantMapsProps => {
  const { gpt } = state;
  const { aiConsultant } = gpt;
  const { chatWindowOpened } = aiConsultant;
  const gptStatus = getGPTStatus(state);
  return {
    opened: chatWindowOpened,
    gptStatus,
  };
};

export const AIConsultant = connect(
  mapStateToProps,
  {
    fetchConversation,
    openChatWindow,
    closeChatWindow,
  },
)(AIConsultantBase);