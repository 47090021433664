import React, { ChangeEvent, Component } from 'react';

import cx from 'classnames';
import { InfoCircleOutlined } from '@ant-design/icons';
import CopyToClipboard from 'react-copy-to-clipboard';

import SxButton, { ISxButtonProps } from '../sxButton';
import SxDropdown, { ISxDropdownOption, ISxDropdownOptionForUiRefresh } from '../sxDropdown';
import SxModal from '../sxModal';
import SxSelection, { ISxSelectOption } from '../sxSelection';
import SxIcon from '../sxIcon';
import SxAlert, { ISxAlertProps } from '../sxAlert';
import SxCheckbox from '../sxCheckbox';
import SxSwitch from '../sxSwitch';
import SxSwitchMultiple from '../sxSwitch/multiple';
import SxLoader from '../sxLoader';
import SxProgress from '../sxProgress';
import SxQuill from '../sxInput/quill';
import SxBreadcrumb from '../sxBreadcrumb';
import { Tooltip } from '../../../common/utils/wrapComponents';
import { Features, isFeatureEnabled } from '../../../../featureToggles/features';
import SxInput from '../sxInput';
import SxTextArea from '../sxInput/textArea';
import { FeatureToggle, Off, On } from '../../../../featureToggles';
import { icons } from '../../../../assets/sightx-icons/icons.interface';
import SxInputButton from '../sxInput/sxInputButton';
import SxTable from '../sxTable';
import { EmptyObject } from '../../../../interfaces/common';
import { SxCheckboxGroupColumns, SxColorPicker, SxCounter, SxInputNumber, SxTrialModal } from '../index';
import { globalClasses } from '../../../../styles/variables';
import { SxDeleteModal } from '../sxModal/sxDeleteModal';
import { ChartColorsUiRefresh } from '../../../analysis/charts/chartColors';
import RestrictedButton from '../../RestrictedButton';
import { SxSwitchableTabs } from '../sxSwitchableTabs';

import styles from './index.module.scss';

const BUTTON_TEXT = 'Button CTA';
const BUTTON_TOOLTIP_MESSAGE = 'Hello I am a message!';
const MEDIUM_TEXT = 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.';

interface ISxLibraryDemo {
  iconFontSize: number;
  deleteModalOpened: boolean[];
  // use for Features.highlight
  counter: number;
  color: string;
  min?: number;
  max?: number;
  step?: number;
  trialModalOpen: boolean[];
}
const showErrorsClassname = globalClasses.showErrors;
export class SxLibraryDemo extends Component<EmptyObject, ISxLibraryDemo> {
  constructor(props) {
    super(props);
    this.state = {
      iconFontSize: 30,
      deleteModalOpened: [
        false,
        false,
        false,
      ],
      counter: 0,
      color: ChartColorsUiRefresh[0],
      trialModalOpen: [
        false,
        false,
      ],
    };
    this.setIconFontSize = this.setIconFontSize.bind(this);
    // use for Features.highlight
    this.handleCounterUpdate = this.handleCounterUpdate.bind(this);
    this.setMinValue = this.setMinValue.bind(this);
    this.setMaxValue = this.setMaxValue.bind(this);
    this.setStep = this.setStep.bind(this);
    this.handleChangeColor = this.handleChangeColor.bind(this);
    this.handleTrialModalClick = this.handleTrialModalClick.bind(this);
  }

  private handleCounterUpdate(value: number) {
    if (!isFeatureEnabled(Features.highlight)) {
      return;
    }
    this.setState({
      counter: value,
    });
  }

  private handleChangeColor(color: string) {
    if (!isFeatureEnabled(Features.highlight)) {
      return;
    }
    this.setState({
      color,
    });
  }

  private handleTrialModalClick(index) {
    const { trialModalOpen } = this.state;
    trialModalOpen[index] = !trialModalOpen[index];
    this.setState({
      trialModalOpen,
    });
  }

  onDeleteModalOpenClick(index) {
    const { deleteModalOpened } = this.state;
    deleteModalOpened[index] = true;
    this.setState({
      deleteModalOpened,
    });
  }

  onDeleteModalOkClick(index) {
    alert('Deleted');
    const { deleteModalOpened } = this.state;
    deleteModalOpened[index] = false;
    this.setState({
      deleteModalOpened,
    });
  }

  onDeleteModalCancelClick(index) {
    alert('Canceled');
    const { deleteModalOpened } = this.state;
    deleteModalOpened[index] = false;
    this.setState({
      deleteModalOpened,
    });
  }

  private setStep(event: ChangeEvent<HTMLInputElement>) {
    this.setState({
      step: parseInt(event.target.value || '0'),
    });
  }

  private setMinValue(event: ChangeEvent<HTMLInputElement>) {
    this.setState({
      min: parseInt(event.target.value || '0'),
    });
  }

  private setMaxValue(event: ChangeEvent<HTMLInputElement>) {
    this.setState({
      max: parseInt(event.target.value || '0'),
    });
  }

  setIconFontSize(event: ChangeEvent<HTMLInputElement>) {
    this.setState({
      iconFontSize: parseInt(event.target.value || '0'),
    });
  }

  doNothing() {
    // doing Nothing
  }

  renderSxButtonBlockByType(type?: Extract<ISxButtonProps, { featureToUse: Features.uirefresh }>['type']) {
    const icon = 'sx-survey-input-radio-off';
    return <>
      <h3>Only icon</h3>
      {this.renderSxButtonRow({ type, icon })}
      {this.renderSxButtonRow({ type, icon, disabled: true })}
      {this.renderSxButtonRow({ type, icon, disabled: true, disabledMessage: BUTTON_TOOLTIP_MESSAGE })}
      {this.renderSxButtonRow({ type, icon, locked: true })}
      <h3>Text</h3>
      {this.renderSxButtonRow({ type })}
      {this.renderSxButtonRow({ type, disabled: true })}
      {this.renderSxButtonRow({ type, disabled: true, disabledMessage: BUTTON_TOOLTIP_MESSAGE })}
      {this.renderSxButtonRow({ type, locked: true })}
      <h3>Icon left</h3>
      {this.renderSxButtonRow({ type, iconLeft: icon })}
      {this.renderSxButtonRow({ type, iconLeft: icon, disabled: true })}
      {this.renderSxButtonRow({ type, iconLeft: icon, disabled: true, disabledMessage: BUTTON_TOOLTIP_MESSAGE })}
      {this.renderSxButtonRow({ type, iconLeft: icon, locked: true })}
      <h3>Icon right</h3>
      {this.renderSxButtonRow({ type, iconRight: icon })}
      {this.renderSxButtonRow({ type, iconRight: icon, disabled: true })}
      {this.renderSxButtonRow({ type, iconRight: icon, disabled: true, disabledMessage: BUTTON_TOOLTIP_MESSAGE })}
      {this.renderSxButtonRow({ type, iconRight: icon, locked: true })}
      <h3>Icon both sides</h3>
      {this.renderSxButtonRow({ type, iconLeft: icon, iconRight: icon })}
      {this.renderSxButtonRow({ type, iconLeft: icon, iconRight: icon, disabled: true })}
      {this.renderSxButtonRow({ type, iconLeft: icon, iconRight: icon, disabled: true, disabledMessage: BUTTON_TOOLTIP_MESSAGE })}
      {this.renderSxButtonRow({ type, iconLeft: icon, locked: true })}
      <h3>With bullet</h3>
      {this.renderSxButtonRow({ type, withBullet: true })}
      {this.renderSxButtonRow({ type, withBullet: true, disabled: true })}
      {this.renderSxButtonRow({ type, withBullet: true, disabled: true, disabledMessage: BUTTON_TOOLTIP_MESSAGE })}
      {this.renderSxButtonRow({ type, withBullet: true, locked: true })}
    </>;
  }

  renderSxButtonRow(params: Omit<Extract<ISxButtonProps, { featureToUse: Features.plgexternal }>, 'featureToUse'>) {
    const { type, icon, iconLeft, iconRight, withBullet, disabled, disabledMessage, locked } = params;
    return <div className={styles.componentList}>
      <SxButton
        type={type}
        size="small"
        disabled={disabled}
        icon={icon}
        iconLeft={iconLeft}
        iconRight={iconRight}
        withBullet={withBullet}
        locked={locked}
        featureToUse={Features.plgexternal}
        disabledMessage={disabledMessage}
      >
        {!icon && BUTTON_TEXT}
      </SxButton>
      <SxButton
        type={type}
        size="medium"
        disabled={disabled}
        icon={icon}
        iconLeft={iconLeft}
        iconRight={iconRight}
        withBullet={withBullet}
        featureToUse={Features.plgexternal}
        locked={locked}
        disabledMessage={disabledMessage}
      >
        {!icon && BUTTON_TEXT}
      </SxButton>
      <SxButton
        type={type}
        size="large"
        disabled={disabled}
        icon={icon}
        iconLeft={iconLeft}
        iconRight={iconRight}
        withBullet={withBullet}
        locked={locked}
        featureToUse={Features.plgexternal}
        disabledMessage={disabledMessage}
      >
        {!icon && BUTTON_TEXT}
      </SxButton>
      <SxButton
        type={type}
        size="extraLarge"
        disabled={disabled}
        icon={icon}
        iconLeft={iconLeft}
        iconRight={iconRight}
        withBullet={withBullet}
        locked={locked}
        featureToUse={Features.plgexternal}
        disabledMessage={disabledMessage}
      >
        {!icon && BUTTON_TEXT}
      </SxButton>
      <SxButton
        type={type}
        size="superLarge"
        disabled={disabled}
        icon={icon}
        iconLeft={iconLeft}
        iconRight={iconRight}
        withBullet={withBullet}
        featureToUse={Features.plgexternal}
        disabledMessage={disabledMessage}
        locked={locked}
      >
        {!icon && BUTTON_TEXT}
      </SxButton>
    </div>;
  }

  renderSxButtonGroup(type: Extract<ISxButtonProps, { featureToUse?: undefined }>['type']) {
    return <>
      <SxButton type={type}>test</SxButton>
      <SxButton type={type} icon="sx-reload">test</SxButton>
      <SxButton type={type} icon="sx-reload" withBorder>test</SxButton>
      <SxButton type={type} icon="sx-reload" isSelected>test</SxButton>
      <SxButton type={type} loading>test</SxButton>
      <SxButton type={type} icon="sx-reload" disabled>test</SxButton>
      <SxButton type={type} icon="sx-reload" disabled disabledMessage="Test Message">test</SxButton>
    </>;
  }

  renderSxButtons() {
    const isUiRefreshEnv = isFeatureEnabled(Features.uirefresh);
    return <div className={styles.componentBlock}>
      <h1>SxButton</h1>
      {
        isUiRefreshEnv &&
        <>
          <h2>Primary</h2>
          {this.renderSxButtonBlockByType('primary')}
          <h2>Danger</h2>
          {this.renderSxButtonBlockByType('danger')}
          <h2>Secondary</h2>
          {this.renderSxButtonBlockByType('secondary')}
          <h2>Hoverable</h2>
          {this.renderSxButtonBlockByType('hoverable')}
          <h2>Link</h2>
          {this.renderSxButtonBlockByType('link')}
          <h2>DarkText</h2>
          {this.renderSxButtonBlockByType('darkText')}
          <h2>Default</h2>
          {this.renderSxButtonBlockByType()}
        </>
      }
      {
        !isUiRefreshEnv && <>
          <span className={styles.subHeader}>Primary</span>
          <div className={styles.componentList}>
            {this.renderSxButtonGroup('primary')}
          </div>
          <span className={styles.subHeader}>Secondary</span>
          <div className={styles.componentList}>
            {this.renderSxButtonGroup('secondary')}
          </div>
          <span className={styles.subHeader}>Danger</span>
          <div className={styles.componentList}>
            {this.renderSxButtonGroup('danger')}
          </div>
          <span className={styles.subHeader}>Text</span>
          <div className={styles.componentList}>
            {this.renderSxButtonGroup('text')}
          </div>
          <span className={styles.subHeader}>GrayText</span>
          <div className={styles.componentList}>
            {this.renderSxButtonGroup('grayText')}
          </div>
          <span className={styles.subHeader}>Darktext</span>
          <div className={styles.componentList}>
            {this.renderSxButtonGroup('darkText')}
          </div>
          <span className={styles.subHeader}>simple</span>
          <div className={styles.componentList}>
            {this.renderSxButtonGroup('simple')}
          </div>
        </>
      }
    </div>;
  }

  renderSxInputs() {
    if (!isFeatureEnabled(Features.uirefresh)) {
      return null;
    }
    return <div className={styles.componentBlock}>
      <h1>SxInput</h1>
      <span className={styles.subHeader}>Basic input</span>
      <div className={styles.componentGrid}>
        <SxInput
          placeholder="olivia@untitledui.com"
        />
        <SxInput
          placeholder="olivia@untitledui.com"
          value="olivia@untitledui.com"
        />
        <SxInput
          placeholder="olivia@untitledui.com"
          value="olivia@untitledui.com"
          focused
        />
        <SxInput
          placeholder="olivia@untitledui.com"
          value="olivia@untitledui.com"
          disabled
        />
      </div>
      <div className={styles.componentGrid}>
        <SxInput
          placeholder="olivia@untitledui.com"
          error
        />
        <SxInput
          placeholder="olivia@untitledui.com"
          value="olivia@untitledui.com"
          error
        />
        <SxInput
          placeholder="olivia@untitledui.com"
          value="olivia@untitledui.com"
          focused
          error
        />
      </div>
      <span className={styles.subHeader}>Input with suffix</span>
      <div className={styles.componentGrid}>
        <SxInput
          placeholder="olivia@untitledui.com"
          suffix={<InfoCircleOutlined/>}
        />
        <SxInput
          placeholder="olivia@untitledui.com"
          suffix={<InfoCircleOutlined/>}
          value="olivia@untitledui.com"
        />
        <SxInput
          placeholder="olivia@untitledui.com"
          suffix={<InfoCircleOutlined/>}
          value="olivia@untitledui.com"
          focused
        />
        <SxInput
          placeholder="olivia@untitledui.com"
          suffix={<InfoCircleOutlined/>}
          value="olivia@untitledui.com"
          disabled
        />
      </div>
      <div className={cx(showErrorsClassname, styles.componentGrid)}>
        <SxInput
          placeholder="olivia@untitledui.com"
          suffix={<InfoCircleOutlined/>}
          error
        />
        <SxInput
          placeholder="olivia@untitledui.com"
          suffix={<InfoCircleOutlined/>}
          value="olivia@untitledui.com"
          error
        />
        <SxInput
          placeholder="olivia@untitledui.com"
          suffix={<InfoCircleOutlined/>}
          value="olivia@untitledui.com"
          focused
          error
        />
      </div>
    </div>;
  }

  renderSxInputButtons() {
    if (!isFeatureEnabled(Features.uirefresh)) {
      return null;
    }
    return (
      <div className={styles.componentBlock}>
        <h1>SxInputButton</h1>
        <span className={styles.subHeader}>InputButtons with suffix & tooltip on both input & button </span>
        <div className={styles.componentGrid}>
          <SxInputButton
            iconName="sx-copy"
            buttonLabel="Copy"
            value="https://survey-test.frontier7.com/123456"
            inputTooltipText="Input tooltip"
            buttonClickTooltipText="Clicked!"
            buttonHoverTooltipText="Click me"
            suffix={<InfoCircleOutlined/>}
          />
          <SxInputButton
            buttonLabel="Copy"
            value="https://survey-test.frontier7.com/123456"
            inputTooltipText="Input tooltip"
            buttonClickTooltipText="Clicked!"
            buttonHoverTooltipText="Click me"
            suffix={<InfoCircleOutlined/>}
          />
          <SxInputButton
            iconName="sx-copy"
            value="https://survey-test.frontier7.com/123456"
            inputTooltipText="Input tooltip"
            buttonClickTooltipText="Clicked!"
            buttonHoverTooltipText="Click me"
            suffix={<InfoCircleOutlined/>}
          />
        </div>
        <span className={styles.subHeader}>InputButtons with tooltip on both input & button</span>
        <div className={styles.componentGrid}>
          <SxInputButton
            iconName="sx-copy"
            buttonLabel="Copy"
            value="https://survey-test.frontier7.com/123456"
            inputTooltipText="Input tooltip"
            buttonClickTooltipText="Clicked!"
            buttonHoverTooltipText="Click me"
          />
          <SxInputButton
            buttonLabel="Copy"
            value="https://survey-test.frontier7.com/123456"
            inputTooltipText="Input tooltip"
            buttonClickTooltipText="Clicked!"
            buttonHoverTooltipText="Click me"
          />
          <SxInputButton
            iconName="sx-copy"
            value="https://survey-test.frontier7.com/123456"
            inputTooltipText="Input tooltip"
            buttonClickTooltipText="Clicked!"
            buttonHoverTooltipText="Click me"
          />
        </div>
        <span className={styles.subHeader}>InputButtons with suffix & tooltip on both input & button with showErrors</span>
        <div className={cx(showErrorsClassname, styles.componentGrid)}>
          <SxInputButton
            iconName="sx-copy"
            buttonLabel="Copy"
            value="https://survey-test.frontier7.com/123456"
            inputTooltipText="Input tooltip"
            buttonClickTooltipText="Clicked!"
            buttonHoverTooltipText="Click me"
            suffix={<InfoCircleOutlined/>}
            error
          />
          <SxInputButton
            buttonLabel="Copy"
            value="https://survey-test.frontier7.com/123456"
            inputTooltipText="Input tooltip"
            buttonClickTooltipText="Clicked!"
            buttonHoverTooltipText="Click me"
            suffix={<InfoCircleOutlined/>}
            error
          />
          <SxInputButton
            iconName="sx-copy"
            value="https://survey-test.frontier7.com/123456"
            inputTooltipText="Input tooltip"
            buttonClickTooltipText="Clicked!"
            buttonHoverTooltipText="Click me"
            suffix={<InfoCircleOutlined/>}
            error
          />
        </div>
        <span className={styles.subHeader}>InputButtons with tooltip on both input & button with showErrors</span>
        <div className={cx(showErrorsClassname, styles.componentGrid)}>
          <SxInputButton
            iconName="sx-copy"
            buttonLabel="Copy"
            value="https://survey-test.frontier7.com/123456"
            inputTooltipText="Input tooltip"
            buttonClickTooltipText="Clicked!"
            buttonHoverTooltipText="Click me"
            error
          />
          <SxInputButton
            buttonLabel="Copy"
            value="https://survey-test.frontier7.com/123456"
            inputTooltipText="Input tooltip"
            buttonClickTooltipText="Clicked!"
            buttonHoverTooltipText="Click me"
            error
          />
          <SxInputButton
            iconName="sx-copy"
            value="https://survey-test.frontier7.com/123456"
            inputTooltipText="Input tooltip"
            buttonClickTooltipText="Clicked!"
            buttonHoverTooltipText="Click me"
            error
          />
        </div>
        <span className={styles.subHeader}>InputButtons with tooltip on button</span>
        <div className={styles.componentGrid}>
          <SxInputButton
            iconName="sx-copy"
            buttonLabel="Copy"
            value="https://survey-test.frontier7.com/123456"
            buttonClickTooltipText="Clicked!"
            buttonHoverTooltipText="Click me"
            readOnly={false}
          />
          <SxInputButton
            buttonLabel="Copy"
            value="https://survey-test.frontier7.com/123456"
            buttonClickTooltipText="Clicked!"
            buttonHoverTooltipText="Click me"
            readOnly={false}
          />
          <SxInputButton
            iconName="sx-copy"
            value="https://survey-test.frontier7.com/123456"
            buttonClickTooltipText="Clicked!"
            buttonHoverTooltipText="Click me"
          />
        </div>
        <span className={styles.subHeader}>InputButtons with tooltip on button but not on click</span>
        <div className={styles.componentGrid}>
          <SxInputButton
            iconName="sx-copy"
            buttonLabel="Copy"
            value="https://survey-test.frontier7.com/123456"
            buttonHoverTooltipText="Click me"
            readOnly={false}
          />
          <SxInputButton
            buttonLabel="Copy"
            value="https://survey-test.frontier7.com/123456"
            buttonHoverTooltipText="Click me"
            readOnly={false}
          />
          <SxInputButton
            iconName="sx-copy"
            value="https://survey-test.frontier7.com/123456"
            buttonHoverTooltipText="Click me"
          />
        </div>
        <span className={styles.subHeader}>InputButtons with tooltip on input</span>
        <div className={styles.componentGrid}>
          <SxInputButton
            iconName="sx-copy"
            buttonLabel="Copy"
            value="https://survey-test.frontier7.com/123456"
            inputTooltipText="Input tooltip"
          />
          <SxInputButton
            buttonLabel="Copy"
            value="https://survey-test.frontier7.com/123456"
            inputTooltipText="Input tooltip"
          />
          <SxInputButton
            iconName="sx-copy"
            value="https://survey-test.frontier7.com/123456"
            inputTooltipText="Input tooltip"
          />
        </div>
        <span className={styles.subHeader}>InputButtons with no tooltips</span>
        <div className={styles.componentGrid}>
          <SxInputButton
            iconName="sx-copy"
            buttonLabel="Copy"
            value="https://survey-test.frontier7.com/123456"
          />
          <SxInputButton
            buttonLabel="Copy"
            value="https://survey-test.frontier7.com/123456"
          />
          <SxInputButton
            iconName="sx-copy"
            value="https://survey-test.frontier7.com/123456"
          />
        </div>
        <span className={styles.subHeader}>InputButtons disabled</span>
        <div className={styles.componentGrid}>
          <SxInputButton
            iconName="sx-copy"
            buttonLabel="Copy"
            value="https://survey-test.frontier7.com/123456"
            inputTooltipText="Disabled"
            buttonHoverTooltipText="Disabled"
            disabled
          />
          <SxInputButton
            buttonLabel="Copy"
            value="https://survey-test.frontier7.com/123456"
            inputTooltipText="Disabled"
            buttonHoverTooltipText="Disabled"
            disabled
          />
          <SxInputButton
            iconName="sx-copy"
            value="https://survey-test.frontier7.com/123456"
            inputTooltipText="Disabled"
            buttonHoverTooltipText="Disabled"
            disabled
          />
        </div>
        <span className={styles.subHeader}>InputButtons with suffix disabled</span>
        <div className={styles.componentGrid}>
          <SxInputButton
            iconName="sx-copy"
            buttonLabel="Copy"
            value="https://survey-test.frontier7.com/123456"
            inputTooltipText="Disabled"
            buttonClickTooltipText="Disabled"
            suffix={<InfoCircleOutlined/>}
            disabled
          />
          <SxInputButton
            buttonLabel="Copy"
            value="https://survey-test.frontier7.com/123456"
            inputTooltipText="Disabled"
            buttonClickTooltipText="Disabled"
            suffix={<InfoCircleOutlined/>}
            disabled
          />
          <SxInputButton
            iconName="sx-copy"
            value="https://survey-test.frontier7.com/123456"
            inputTooltipText="Disabled"
            buttonClickTooltipText="Disabled"
            suffix={<InfoCircleOutlined/>}
            disabled
          />
        </div>
      </div>
    );
  }

  renderSxTextArea() {
    if (!isFeatureEnabled(Features.uirefresh)) {
      return null;
    }
    return <div className={styles.componentBlock}>
      <h1>SxTextArea</h1>
      <div className={styles.componentGrid}>
        <SxTextArea
          placeholder="Enter a description"
          autoSize={{ minRows: 3 }}
        />
        <SxTextArea
          placeholder="Enter a description"
          value="A little about the company and the team that you'll be working with."
          autoSize={{ minRows: 3 }}
        />
        <SxTextArea
          placeholder="Enter a description"
          value="A little about the company and the team that you'll be working with."
          autoSize={{ minRows: 3 }}
          focused
        />
        <SxTextArea
          placeholder="Enter a description"
          value="A little about the company and the team that you'll be working with."
          autoSize={{ minRows: 3 }}
          disabled
        />
      </div>
      <span className={styles.subHeader}>With showErrors</span>
      <div className={cx(showErrorsClassname, styles.componentGrid)}>
        <SxTextArea
          placeholder="Enter a description"
          autoSize={{ minRows: 3 }}
          status="error"
        />
        <SxTextArea
          placeholder="Enter a description"
          value="A little about the company and the team that you'll be working with."
          autoSize={{ minRows: 3 }}
          status="error"
        />
        <SxTextArea
          placeholder="Enter a description"
          value="A little about the company and the team that you'll be working with."
          autoSize={{ minRows: 3 }}
          status="error"
          focused
        />
      </div>
    </div>;
  }

  renderSxSelections() {
    const isUiRefreshEnv = isFeatureEnabled(Features.uirefresh);
    const options: ISxSelectOption<string>[] = [
      { label: 'Option label', value: 'testValue1' },
      { text: 'Option text', value: 'testValue2' },
      { label: <div>Option label <b>node</b></div>, value: 'testValue3' },
      { label: 'disabled Option', value: 'testValue4', disabled: true },
      { label: <div><span>Option <small>label</small> <b>node 2</b></span></div>, value: 'testValue5' },
      { label: 'Not locked Option', value: 'testValue6' },
      { label: 'locked Option', value: 'testValue7', locked: true },
    ];

    const optionsUiRefresh: ISxSelectOption<string>[] = [
      { label: 'Phoenix Baker', value: 'phoenix', description: '@phoenix' },
      { text: 'Olivia Rhye', value: 'olivia', description: '@olivia' },
      { text: 'Lana Steiner', value: 'lana', description: '@lana' },
      { text: 'Demi  Wilkinson', value: 'demi', description: '@demi' },
      { text: 'Candice Wu', value: 'candice', description: '@candice' },
      { text: 'Natali Craig', value: 'natali', description: '@natali' },
      { text: 'Drew Cano', value: 'drew', description: '@drew' },
      { text: 'Test User', value: 'test', description: '@test' },
      { label: 'Peter Baker', value: 'peter', description: '@peter' },
      { text: 'Samantha Who', value: 'smantha', description: '@saman' },
    ];

    const commonProps = {
      options,
      className: styles.sxSelection,
      onSelect: this.doNothing,
      allowSearch: false,
      hint: 'This is a hint text to help user',
    };
    if (isUiRefreshEnv) {
      return <div className={styles.componentBlock}>
        <h1>SxSelection</h1>
        <h3>Single selection</h3>
        <div className={cx(styles.componentGrid, { [styles.sxSelectionGrid]: isUiRefreshEnv })}>
          <div>
            <SxSelection featureToUse={Features.uirefresh} label="Team Member" placeholder="Select team member" {...commonProps} options={options} className="" />
          </div>
          <div className={showErrorsClassname}>
            <SxSelection featureToUse={Features.uirefresh} label="Team Member with Error" placeholder="Select team member" {...commonProps} options={optionsUiRefresh} className="" error />
          </div>
          <div>
            <SxSelection featureToUse={Features.uirefresh} label="Team Member disabled mode" placeholder="Select team member" {...commonProps} options={optionsUiRefresh} className="" disabled />
          </div>
          <div>
            <SxSelection featureToUse={Features.uirefresh} label="Team Member disabled mode" placeholder="Select team member" {...commonProps} options={optionsUiRefresh} className="" disabled/>
          </div>
          <div>
            <SxSelection featureToUse={Features.uirefresh} label="Team Member with search" placeholder="Select team member" {...commonProps} options={optionsUiRefresh} className="" allowSearch />
          </div>
          <div>
            <SxSelection featureToUse={Features.uirefresh} label="Team Member with search and disabled" placeholder="Select team member" {...commonProps} options={optionsUiRefresh} className="" allowSearch disabled />
          </div>
          <div>
            <SxSelection featureToUse={Features.uirefresh} label="Team Member with allow null" placeholder="Select team member" {...commonProps} options={optionsUiRefresh} className="" allowNull />
          </div>
          <div>
            <SxSelection
              featureToUse={Features.plgexternal}
              label="Locked select"
              placeholder="Locked select" {...commonProps}
              locked
              options={options}
              className=""
            />
          </div>
        </div>
        <h3>Multiple selection</h3>
        <div className={cx(styles.componentGrid, { [styles.sxSelectionGrid]: isUiRefreshEnv })}>
          <div>
            <SxSelection featureToUse={Features.uirefresh} label="Team Member multiple selection" placeholder="Select team member" {...commonProps} options={options} className="" allowMultipleSelection />
          </div>
          <div>
            <label>Sin label propio</label>
            <SxSelection featureToUse={Features.uirefresh} placeholder="Select team member" {...commonProps} options={options} className="" allowMultipleSelection hint="" />
          </div>
          <div className={showErrorsClassname}>
            <SxSelection featureToUse={Features.uirefresh} label="Team Member multiple selection error" placeholder="Select team member" {...commonProps} options={optionsUiRefresh} className="" allowMultipleSelection error />
          </div>
          <div>
            <SxSelection featureToUse={Features.uirefresh} label="Team Member multiple and disabled" placeholder="Select team member" {...commonProps} options={optionsUiRefresh} className="" allowMultipleSelection disabled/>
          </div>
          <div>
            <SxSelection featureToUse={Features.uirefresh} label="Team Member multiple and null" placeholder="Select team member" {...commonProps} options={optionsUiRefresh} className="" allowMultipleSelection allowNull/>
          </div>
          <div>
            <SxSelection featureToUse={Features.uirefresh} label="Team Member multiple and search" placeholder="Select team member" {...commonProps} options={optionsUiRefresh} className="" allowSearch allowMultipleSelection />
          </div>
          <div className={showErrorsClassname}>
            <SxSelection featureToUse={Features.uirefresh} label="Team Member multiple and search error" placeholder="Select team member" {...commonProps} options={optionsUiRefresh} className="" allowSearch allowMultipleSelection error />
          </div>
          <div>
            <SxSelection featureToUse={Features.uirefresh} label="Team Member multiple search and disabled" placeholder="Select team member" {...commonProps} options={optionsUiRefresh} className="" allowSearch allowMultipleSelection disabled/>
          </div>
        </div>
      </div>;
    }
    return <div className={styles.componentBlock}>
      <h1>SxSelection</h1>
      <div className={styles.componentGrid}>
        <div>
          <span className={styles.subHeader}>Single Selection Normal</span>
          <SxSelection {...commonProps} />
        </div>
        <div>
          <span className={styles.subHeader}>Single Selection (Optional)</span>
          <SxSelection {...commonProps} allowNull />
        </div>
        <div>
          <span className={styles.subHeader}>Single Selection with Search</span>
          <SxSelection {...commonProps} allowSearch />
        </div>
        <div>
          <span className={styles.subHeader}>Single Selection Error</span>
          <SxSelection {...commonProps} error />
        </div>
        <div>
          <span className={styles.subHeader}>Single Selection transparent</span>
          <SxSelection {...commonProps} type="text" />
        </div>
        <div>
          <span className={styles.subHeader}>Single Selection Disabled</span>
          <SxSelection {...commonProps} disabled />
        </div>
      </div>
      <div className={styles.componentGrid}>
        <div>
          <span className={styles.subHeader}>Multiple Selection Normal</span>
          <SxSelection {...commonProps} allowMultipleSelection />
        </div>
        <div>
          <span className={styles.subHeader}>Multiple Selection (Optional)</span>
          <SxSelection {...commonProps} allowMultipleSelection allowNull />
        </div>
        <div>
          <span className={styles.subHeader}>Multiple Selection with Search</span>
          <SxSelection {...commonProps} allowMultipleSelection allowSearch />
        </div>
        <div>
          <span className={styles.subHeader}>Multiple Selection Error</span>
          <SxSelection {...commonProps} allowMultipleSelection error />
        </div>
        <div>
          <span className={styles.subHeader}>Single Selection transparent</span>
          <SxSelection {...commonProps} allowMultipleSelection type="text" />
        </div>
        <div>
          <span className={styles.subHeader}>Multiple Selection Disabled</span>
          <SxSelection {...commonProps} allowMultipleSelection disabled />
        </div>
      </div>
    </div>;
  }

  renderSxDropdowns() {
    const options: ISxDropdownOption<string>[] = [
      {
        label: 'label for test 1',
        value: '1',
      },
      {
        label: 'label for test 2',
        icon: 'sx-trash',
        value: '2',
      },
      {
        label: 'label for test 3',
        disabled: true,
        value: '3',
      },
      {
        label: 'label for test 4',
        icon: 'sx-trash',
        value: '4',
        disabled: true,
      },
    ];

    const optionsForUiRefresh: ISxDropdownOptionForUiRefresh<string>[] = [
      {
        type: 'customOption',
        content: <div key="customOption" style={{ padding: '20px' }}>I'm a custom content</div>,
      },
      {
        type: 'breakline',
      },
      {
        label: 'label for test',
        value: '1',
      },
      {
        label: 'label for test',
        disabled: true,
        value: '3',
      },
      {
        type: 'breakline',
      },
      {
        label: 'label for test',
        icon: 'sx-add-user',
        value: '4',
        disabled: true,
      },
      {
        label: 'large test label for demo file',
        icon: 'sx-trash',
        value: '2',
      },
      {
        type: 'breakline',
      },
      {
        label: 'label for test',
        icon: 'sx-archive',
        value: '2',
        rightLabel: <span>Ctrl + c</span>,
      },
      {
        label: 'label for test',
        icon: 'sx-archive',
        value: '2',
        disabled: true,
        rightLabel: <span>Ctrl + x</span>,
      },
    ];

    const commonProps = {
      options,
      onSelect: this.doNothing,
      children: <SxButton>Open Dropdown Click</SxButton>,
      trigger: 'click' as const,
    };

    let toberendered = <SxDropdown {...commonProps} />;

    if (isFeatureEnabled(Features.uirefresh)) {
      toberendered = <SxDropdown {...commonProps} options={optionsForUiRefresh} featureToUse={Features.uirefresh} />;
    }

    return <div className={styles.componentBlock}>
      <h1>SxDropdown</h1>
      <div className={styles.componentGrid}>
        {toberendered}
        <SxDropdown
          children={<SxButton>Open Dropdown Hover</SxButton>}
          trigger="hover"
          content={<div style={{ padding: '60px' }}>I'm a custom content</div>}
        />
      </div>
    </div>;
  }

  renderSxModals() {
    const uiRefreshModalSizes = ['extraSmall', 'small', 'medium', 'large', 'extraLarge'] as const;
    return <div className={styles.componentBlock}>
      <h1>SxModal</h1>
      <FeatureToggle feature={Features.uirefresh}>
        <Off>
          <div className={styles.componentList}>
            <SxModal trigger={<SxButton>Open Modal</SxButton>} title="TEST MODAL">
              TEST MODAL CONTENT
            </SxModal>
            <SxModal showCloseIcon trigger={<SxButton>Open Modal with close icon</SxButton>} title="TEST MODAL">
              TEST MODAL CONTENT
            </SxModal>
            <SxModal
              titleIcon={<SxIcon name="sx-trash" style={{ fontSize: '20px', color: 'black', marginRight: '10px' }} />}
              trigger={<SxButton>Open Modal with title icon</SxButton>}
              title="TEST MODAL"
            >
              TEST MODAL CONTENT
            </SxModal>
            <SxModal purpleHeader showCloseIcon trigger={<SxButton>Open Modal with Purple Header</SxButton>} title="TEST MODAL">
              TEST MODAL CONTENT
            </SxModal>
          </div>
        </Off>
        <On>
          {uiRefreshModalSizes.map((size) => (
            <>
              <h2>Size: {size}</h2>
              <div className={styles.componentList}>
                <SxModal
                  featureToUse={Features.uirefresh}
                  size={size}
                  trigger={
                    <SxButton type="primary"
                      size="medium"
                      featureToUse={Features.uirefresh}
                    >Open Modal</SxButton>
                  }
                  title="TEST MODAL"
                >
                  TEST MODAL CONTENT
                </SxModal>
                <SxModal
                  featureToUse={Features.uirefresh}
                  showCloseIcon
                  size={size}
                  trigger={
                    <SxButton
                      type="primary"
                      size="medium"
                      featureToUse={Features.uirefresh}
                    >
                      Open Modal with close icon
                    </SxButton>
                  } title="TEST MODAL">
                  TEST MODAL CONTENT
                </SxModal>
                <SxModal
                  featureToUse={Features.uirefresh}
                  showCloseIcon
                  size={size}
                  trigger={
                    <SxButton
                      type="primary"
                      size="medium"
                      featureToUse={Features.uirefresh}
                    >
                      Open Modal with close icon and title sub component (SxButton)
                    </SxButton>
                  }
                  title="TEST MODAL"
                  titleSubComponent={
                    <SxButton
                      type="link"
                      size="medium"
                      featureToUse={Features.uirefresh}
                      children="Action"
                      iconLeft="sx-lines"
                    />
                  }
                >
                  TEST MODAL CONTENT
                </SxModal>
                <SxModal
                  featureToUse={Features.uirefresh}
                  size={size}
                  noFooter
                  showCloseIcon
                  trigger={
                    <SxButton
                      type="primary"
                      size="medium"
                      featureToUse={Features.uirefresh}
                    >
                      Open Modal with close icon and without footer
                    </SxButton>
                  } title="TEST MODAL">
                  TEST MODAL CONTENT
                </SxModal>
              </div>
            </>
          ))}
        </On>
      </FeatureToggle>
    </div>;
  }

  renderSxModalsFixedWidth() {
    if (!isFeatureEnabled(Features.uirefresh)) {
      return null;
    }
    const uiRefreshModalSizes = ['extraSmall', 'small', 'medium', 'large', 'extraLarge'] as const;
    return <div className={styles.componentBlock}>
      <h1>SxModal with fixed width (fixedWidth)</h1>
      {uiRefreshModalSizes.map((size) => (
        <>
          <h2>Size: {size}</h2>
          <div className={styles.componentList}>
            <SxModal
              fixedWidth
              featureToUse={Features.uirefresh}
              size={size}
              trigger={
                <SxButton type="primary"
                  size="medium"
                  featureToUse={Features.uirefresh}
                >Open Modal</SxButton>
              }
              title="TEST MODAL"
            >
              TEST MODAL CONTENT
            </SxModal>
            <SxModal
              fixedWidth
              featureToUse={Features.uirefresh}
              showCloseIcon
              size={size}
              trigger={
                <SxButton
                  type="primary"
                  size="medium"
                  featureToUse={Features.uirefresh}
                >
                  Open Modal with close icon
                </SxButton>
              } title="TEST MODAL">
              TEST MODAL CONTENT
            </SxModal>
            <SxModal
              fixedWidth
              featureToUse={Features.uirefresh}
              showCloseIcon
              size={size}
              trigger={
                <SxButton
                  type="primary"
                  size="medium"
                  featureToUse={Features.uirefresh}
                >
                  Open Modal with close icon and title sub component (SxButton)
                </SxButton>
              }
              title="TEST MODAL"
              titleSubComponent={
                <SxButton
                  type="link"
                  size="medium"
                  featureToUse={Features.uirefresh}
                  children="Action"
                  iconLeft="sx-lines"
                />
              }
            >
              TEST MODAL CONTENT
            </SxModal>
            <SxModal
              fixedWidth
              featureToUse={Features.uirefresh}
              size={size}
              noFooter
              showCloseIcon
              trigger={
                <SxButton
                  type="primary"
                  size="medium"
                  featureToUse={Features.uirefresh}
                >
                  Open Modal with close icon and without footer
                </SxButton>
              } title="TEST MODAL">
              TEST MODAL CONTENT
            </SxModal>
          </div>
        </>
      ))}
    </div>;
  }

  renderSxDeleteModals() {
    const { deleteModalOpened } = this.state;
    return <div className={styles.componentBlock}>
      <h1>SxDeleteModal</h1>
      <FeatureToggle feature={Features.uirefresh}>
        <Off>NO SUPPORTED</Off>
        <On>
          <div className={styles.componentList}>
            <SxDeleteModal
              trigger={<SxButton featureToUse={Features.uirefresh} type="danger" onClick={this.onDeleteModalOpenClick.bind(this, 0)} >Delete</SxButton>}
              open={deleteModalOpened[0]}
              onOkClick={this.onDeleteModalOkClick.bind(this, 0)}
              onCancelClick={this.onDeleteModalCancelClick.bind(this, 0)}
              title="Delete Quotas"
              description={
                <>
                  <p>You are about to delete the quotas on this question.</p>
                  <p>Are you sure you want to proceed?</p>
                </>
              }
            />
            <SxDeleteModal
              trigger={<SxButton featureToUse={Features.uirefresh} type="danger" onClick={this.onDeleteModalOpenClick.bind(this, 1)} >Delete with confirm</SxButton>}
              open={deleteModalOpened[1]}
              onOkClick={this.onDeleteModalOkClick.bind(this, 1)}
              onCancelClick={this.onDeleteModalCancelClick.bind(this, 1)}
              title="Delete Quotas"
              confirmText="DELETE"
              description={
                <>
                  <p>You are about to delete the quotas on this question.</p>
                  <p>Are you sure you want to proceed?</p>
                </>
              }
            />
            <SxDeleteModal
              trigger={<SxButton featureToUse={Features.uirefresh} type="danger" onClick={this.onDeleteModalOpenClick.bind(this, 2)} >Delete custom content</SxButton>}
              open={deleteModalOpened[2]}
              onCancelClick={this.onDeleteModalCancelClick.bind(this, 2)}
              title="Delete page"
              description={
                <>
                  <p style={{ marginBottom: '24px' }}>You have two options to choose from:</p>
                  <p>Delete just the page, and move the questions with the page to the prior page.</p>
                  <SxButton featureToUse={Features.uirefresh} onClick={this.onDeleteModalOkClick.bind(this, 2)} type="danger">Delete page</SxButton>
                  <p style={{ marginTop: '16px' }}>Delete the page and all of the questions inside of the page from the survey.</p>
                  <SxButton featureToUse={Features.uirefresh} onClick={this.onDeleteModalOkClick.bind(this, 2)} type="danger">Delete page and questions</SxButton>
                </>
              }
            />
          </div>
        </On>
      </FeatureToggle>
    </div>;
  }

  renderSxAlert(type: ISxAlertProps['type']) {
    const title = 'Find Optimal Combinations: ';
    const subtext = 'Select a card below to find the solutions with the highest reach!';
    const description = <ul>
      <li>I'm a text description for demo</li>
      <li>I'm a text description for demo</li>
      <li>I'm a text description for demo</li>
    </ul>;

    const descriptionWithStyle = <ul style={ { paddingLeft: '31px' }}>
      <li>A short statement bullet here</li>
      <li>A medium length statement bullet here that goes up to the middle of the banner.</li>
      <li>A super long statement bullet here that reaches all the way to the very end of this banner alert.</li>
    </ul>;

    return <FeatureToggle feature={Features.uirefresh}>
      <Off>
        <>
          <SxAlert type={type} title={title} />
          <SxAlert type={type} title={title} showIcon closable />
          <SxAlert type={type} description={'I\'m a text description for demo'} />
          <SxAlert type={type} description={'I\'m a text description for demo'} showIcon closable/>
          <SxAlert type={type} title="TEST" description={'I\'m a text description for demo'} />
          <SxAlert type={type} title="TEST" description={'I\'m a text description for demo'} showIcon closable />
        </>
      </Off>
      <On>
        <>
          <SxAlert type={type} featureToUse={Features.uirefresh} title={title} subTextForTitle={subtext}/>
          <SxAlert type={type} featureToUse={Features.uirefresh} title={title} subTextForTitle={subtext} closable />
          <SxAlert type={type} featureToUse={Features.uirefresh} title={title} subTextForTitle={subtext} closable centerContent />
          <SxAlert type={type} featureToUse={Features.uirefresh} title={title} subTextForTitle={subtext} centerContent />
          <SxAlert type={type} featureToUse={Features.uirefresh} title={title} subTextForTitle={subtext} showIcon closable />
          <SxAlert type={type} featureToUse={Features.uirefresh} description={description} />
          <SxAlert type={type} featureToUse={Features.uirefresh} description={description} showIcon closable />
          <SxAlert type={type} featureToUse={Features.uirefresh} title="Title of the banner alert here" subTextForTitle={subtext} description={descriptionWithStyle}/>
          <SxAlert type={type} featureToUse={Features.uirefresh} title="Title of the banner alert here" subTextForTitle={subtext} description={descriptionWithStyle} showIcon closable />
        </>
      </On>
    </FeatureToggle>;
  }

  renderSxAlerts() {
    return <div className={styles.componentBlock}>
      <h1>SxAlerts</h1>
      <div className={styles.componentList}>
        {this.renderSxAlert('info')}
        {this.renderSxAlert('success')}
        {this.renderSxAlert('warning')}
        {this.renderSxAlert('error')}
        <FeatureToggle feature={Features.uirefresh}>
          <Off>
            {this.renderSxAlert('teal')}
          </Off>
          <On></On>
        </FeatureToggle>
      </div>
    </div>;
  }

  renderSxCheckboxes() {
    return <div className={styles.componentBlock}>
      <h1>SxCheckBoxs</h1>
      <div className={styles.componentGrid}>
        <SxCheckbox onChange={this.doNothing} label="Prop label" description="Optional description text." />
        <SxCheckbox onChange={this.doNothing} label="Without description" />
        <SxCheckbox onChange={this.doNothing} description="Optional description text."><i>Children label</i></SxCheckbox>
        <SxCheckbox onChange={this.doNothing} disabled description="Optional description text.">Disabled</SxCheckbox>
        <SxCheckbox onChange={this.doNothing} indeterminate label="Inderteminate" description="Optional description text." />
      </div>
    </div>;
  }

  private renderSxCheckboxGroupColumns() {
    const defaultOptions = Array.from('abcdefghijklmnopqrstuvwxyz').map((char) => {
      return {
        text: char.repeat(5),
        value: char,
      };
    });
    const selectedOptions = ['a', 'f', 'x'];
    return <div className={styles.componentBlock}>
      <h1>SxCheckbox Group by columns</h1>
      <h2>Group with 4 columns</h2>
      <div className={styles.componentList}>
        <SxCheckboxGroupColumns
          onChange={this.doNothing}
          options={defaultOptions}
          selectedValues={selectedOptions}
          colsPerRow={4}
        />
      </div>
      <h2>Group with 2 columns and disabled</h2>
      <div className={styles.componentList}>
        <SxCheckboxGroupColumns
          onChange={this.doNothing}
          options={defaultOptions}
          selectedValues={selectedOptions}
          disabled
          colsPerRow={2}
        />
      </div>
      <h2>Group with 3 columns and searchable</h2>
      <div className={styles.componentList}>
        <SxCheckboxGroupColumns
          onChange={this.doNothing}
          options={defaultOptions}
          selectedValues={selectedOptions}
          searchable
          colsPerRow={3}
        />
      </div>
      <h2>Group with 1 column, searchable and disabled</h2>
      <div className={styles.componentList}>
        <SxCheckboxGroupColumns
          onChange={this.doNothing}
          options={defaultOptions}
          selectedValues={selectedOptions}
          searchable
          disabled
        />
      </div>
    </div>;
  }

  renderSxSwitch() {
    const isUiRefreshEnv = isFeatureEnabled(Features.uirefresh);

    return <div className={styles.componentBlock}>
      <h1>SxSwitchs</h1>
      {
        !isUiRefreshEnv && <div className={styles.componentGrid}>
          <SxSwitch onChange={this.doNothing} label="label" />
          <SxSwitch onChange={this.doNothing}><b>children label</b></SxSwitch>
          <SxSwitch onChange={this.doNothing} disabled><b>disabled</b></SxSwitch>
        </div>
      }
      {
        isUiRefreshEnv && <div className={cx(styles.componentGrid, { [styles.switchGrid]: isUiRefreshEnv })}>
          <SxSwitch onChange={this.doNothing} label="Remember me" description="Save my details login for next time"/>
          <SxSwitch onChange={this.doNothing} label="Remember me" description="Save my details login for next time" checked={true}/>
          <SxSwitch onChange={this.doNothing} description="Save my details login for next time" disabled />
          <SxSwitch onChange={this.doNothing} label="Remember me" />
          <SxSwitch onChange={this.doNothing}>
            <b>Remember me children</b><br/>Save my details login for next time
          </SxSwitch>
          <SxSwitch
            label={
              <div>
                Expand columns <Tooltip
                  Component={<span><SxIcon className={styles.infoIcon} name="sx-icon-info" /></span>}
                  tooltipText="For questions with discrete variables, this option allows
                    you to expand the export file to show each option in its own column"
                />
              </div>
            }
            onChange={this.doNothing}/>
          <SxSwitch onChange={this.doNothing}>
            <div>
              Expand columns <Tooltip
                Component={<span><SxIcon className={styles.infoIcon} name="sx-icon-info" /></span>}
                tooltipText="For questions with discrete variables, this option allows
                  you to expand the export file to show each option in its own column"
              />
            </div>
          </SxSwitch>
          <SxSwitch
            onChange={this.doNothing}
            checkedChildren="On"
            unCheckedChildren="Off"
            size="default"
          />
          <SxSwitch onChange={this.doNothing} disabled><b>disabled</b></SxSwitch>
          <SxSwitch onChange={this.doNothing} />
          <SxSwitch onChange={this.doNothing} disabled />
          <SxSwitch onChange={this.doNothing} disabled checked={true} />
          <>
            <SxSwitch onChange={this.doNothing} locked label="locked feature"/>
            <SxSwitch onChange={this.doNothing} locked label="locked feature" description="test description"/>
          </>
        </div>
      }
    </div>;
  }

  renderArrowLeftIcon() {
    return <SxIcon name="sx-arrow-left"/>;
  }

  renderArrowRightIcon() {
    return <SxIcon name="sx-arrow-right"/>;
  }

  renderSxSwitchMultiple() {
    const commonProps = {
      blocks: [
        {
          label: 'label 1',
          value: 'value 1',
        },
        {
          label: <b>node label</b>,
          value: 'value 2',
        },
        {
          label: 'label 3',
          value: 'value 3',
          disabled: true,
        },
        {
          label: 'label 4',
          value: 'value 4',
        },
      ],
      onSelect: this.doNothing,
    };

    const iconBlocks = [{
      label: this.renderArrowLeftIcon(),
      value: 'left',
    }, {
      label: <SxIcon name="sx-plus" />,
      value: 'plus',
    }, {
      label: <span><SxIcon name="sx-survey-input-radio-off" /> Text</span>,
      value: 'with-text',
    }, {
      label: this.renderArrowRightIcon(),
      value: 'right',
    }];

    return <>
      <div className={styles.componentBlock}>
        <h1>SxSwitchMultiple</h1>
        <div className={styles.componentList}>
          <SxSwitchMultiple {...commonProps} blocks={[{ label: 'label 1', value: 'value 1' }]} value={'value 1'} />
          <SxSwitchMultiple {...commonProps} value={'value 1'} />
          <SxSwitchMultiple {...commonProps} value={['value 1', 'value 4']} />
          <SxSwitchMultiple {...commonProps} value={[]} disabled />
          <SxSwitchMultiple {...commonProps} value={['value 1', 'value 3', 'value 4']} disabled />
          <>
            <SxSwitchMultiple {...commonProps} blocks={[{ label: 'label 1', value: 'value 1', locked: true }]} value={[]} />
            <SxSwitchMultiple {...commonProps} value={[]} locked />
            <SxSwitchMultiple {...commonProps} value={['value 1', 'value 3', 'value 4']} locked />
          </>
          <SxSwitchMultiple onSelect={this.doNothing} blocks={iconBlocks} value={['plus']} />
        </div>
      </div>
      <div className={styles.componentBlock}>
        <h1>SxSwitchableTab</h1>
        <div className={styles.componentList}>
          <SxSwitchableTabs {...commonProps} tabs={[{ label: 'Level importance', value: 'value 1' }, { label: 'Attribute performance', value: 'value 2' }]} value={'value 1'} />
          <SxSwitchableTabs {...commonProps} tabs={[{ label: 'Level importance', value: 'value 1' }, { label: 'Attribute performance', value: 'value 2' }]} value={'value 2'} />
          <SxSwitchableTabs {...commonProps} tabs={[{ label: 'Level importance', value: 'value 1' }, { label: 'Attribute performance', value: 'value 2' }]} value={'value 2'} disabled/>
          <SxSwitchableTabs {...commonProps} tabs={[{ label: 'Level importance', value: 'value 1' }, { label: 'Attribute performance', value: 'value 2' }]} value={'value 2'} locked/>
        </div>
      </div>
    </>;
  }

  renderSxLoaders() {
    return <div className={styles.componentBlock}>
      <h1>SxLoaders</h1>
      <div className={styles.componentGrid}>
        <SxLoader inline />
        <SxLoader inline size="medium" />
        <SxLoader inline size="small" />
      </div>
    </div>;
  }

  renderSxProgress() {
    return <div className={styles.componentBlock}>
      <h1>SxLoaders</h1>
      <div className={styles.componentGrid}>
        <SxProgress type="circle" percent={30} label="30%" />
        <SxProgress type="line" percent={40} label="40%" />
      </div>
    </div>;
  }

  renderSxQuill() {
    return <div className={styles.componentBlock}>
      <h1>SxQuill</h1>
      <div className={styles.componentList}>
        <SxQuill />
      </div>
    </div>;
  }

  renderSxBreadcrumb() {
    return <div className={styles.componentBlock}>
      <h1>SxBreadcrumb</h1>
      <div className={styles.componentList}>
        <SxBreadcrumb
          routes={[
            {
              path: '',
              breadcrumbName: 'Home',
            },
            {
              path: '',
              breadcrumbName: 'Application Center',
            },
            {
              path: '',
              breadcrumbName: 'Application List',
            },
            {
              path: '',
              breadcrumbName: 'An Application',
            },
          ]} />
      </div>
      <div className={styles.componentList}>
        <SxBreadcrumb
          routes={[
            {
              path: '',
              breadcrumbName: 'Home',
              iconName: 'sx-very-happy-fill',
            },
            {
              path: '',
              breadcrumbName: 'Application Center',
            },
            {
              path: '',
              breadcrumbName: 'Application List',
            },
            {
              path: '',
              breadcrumbName: 'An Application',
            },
          ]} />
      </div>
    </div>;
  }

  renderSxIcon() {
    const { iconFontSize } = this.state;
    const defaultSize = 30;
    const size = `${iconFontSize || defaultSize}px`;
    return <div className={styles.componentBlock}>
      <h1>Icons <SxInput min={4} max={100} type="number" placeholder="size" style={{ width: 70 }} value={iconFontSize} onChange={this.setIconFontSize} /></h1>
      <div className={styles.componentList}>
        {
          icons.map((name, index) => (
            <div className={styles.iconWrapper} key={`${name}-${index}`}>
              <div className={styles.iconContainer}>
                <FeatureToggle feature={Features.uirefresh}>
                  <Off>
                    <SxIcon name={name} fontSize={size} />
                  </Off>
                  <On>
                    <CopyToClipboard text={name}>
                      <SxIcon name={name} featureToUse={Features.uirefresh} fontSize={size} />
                    </CopyToClipboard>
                  </On>
                </FeatureToggle>
              </div>
              <label>{name}</label>
            </div>
          ))
        }
      </div>
    </div>;
  }

  renderTable() {
    const dataSource = [
      {
        key: '1',
        name: 'Mike',
        age: 32,
        address: '10 Downing Street',
      },
      {
        key: '2',
        name: 'John',
        age: 42,
        address: '10 Downing Street',
      },
    ];

    const columns = [
      {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
      },
      {
        title: 'Age',
        dataIndex: 'age',
        key: 'age',
      },
      {
        title: 'Address',
        dataIndex: 'address',
        key: 'address',
      },
    ];

    return <div className={styles.componentBlock}>
      <h1>Table</h1>
      <div>
        <h2>Floating header</h2>
        <SxTable dataSource={dataSource} columns={columns} pagination={false} floatingHeader />
        <h2>Normal header</h2>
        <SxTable dataSource={dataSource} columns={columns} pagination={false} />
      </div>
    </div>;
  }

  renderSxInputNumbers() {
    if (!isFeatureEnabled(Features.uirefresh)) {
      return null;
    }
    return <div className={cx(showErrorsClassname, styles.componentBlock)}>
      <h1>SxInputNumber</h1>
      <span className={styles.subHeader}>Basic numeric input</span>
      <div className={styles.componentGrid}>
        <SxInputNumber placeholder="without controls" />
        <SxInputNumber placeholder="without controls" disabled />
        <SxInputNumber placeholder="without controls" hasError />
        <SxInputNumber placeholder="with controls" controls />
        <SxInputNumber placeholder="with controls" controls disabled />
        <SxInputNumber placeholder="with controls" controls hasError />
      </div>
      <span className={styles.subHeader}>Input with no decimals</span>
      <div className={styles.componentGrid}>
        <SxInputNumber value={5} />
        <SxInputNumber value={5} disabled />
        <SxInputNumber value={5} disabled />
      </div>
      <span className={styles.subHeader}>Input with 2 decimals precision</span>
      <div className={styles.componentGrid}>
        <SxInputNumber value={5} precision={2} />
        <SxInputNumber value={5} precision={2} disabled />
        <SxInputNumber value={5} precision={2} hasError />
      </div>
    </div>;
  }

  renderTooltip() {
    if (!isFeatureEnabled(Features.uirefresh)) {
      return null;
    }

    return <div className={cx(showErrorsClassname, styles.componentBlock)}>
      <h1>Tooltip Wrapper</h1>
      <div className={styles.componentGrid}>
        <Tooltip
          Component={
            <SxButton
              type="primary"
              featureToUse={Features.uirefresh}
            >
            Tooltip trigger click on right
            </SxButton>
          }
          trigger="click"
          featureToUse={Features.uirefresh}
          placement="right"
          tooltipText={MEDIUM_TEXT}
        />
        <Tooltip
          Component={
            <SxButton
              type="primary"
              featureToUse={Features.uirefresh}
            >
            Tooltip trigger hover on top
            </SxButton>
          }
          featureToUse={Features.uirefresh}
          placement="top"
          tooltipText={MEDIUM_TEXT}
        />
        <Tooltip
          Component={
            <SxButton
              type="primary"
              featureToUse={Features.uirefresh}
            >
            Tooltip trigger hover on left
            </SxButton>
          }
          featureToUse={Features.uirefresh}
          placement="left"
          tooltipText={MEDIUM_TEXT}
        />
        <Tooltip
          Component={
            <SxButton
              type="primary"
              featureToUse={Features.uirefresh}
            >
            Tooltip trigger click on bottom
            </SxButton>
          }
          trigger="click"
          featureToUse={Features.uirefresh}
          placement="bottom"
          tooltipText={MEDIUM_TEXT}
        />
      </div>
    </div>;
  }

  renderSxCounter() {
    if (!isFeatureEnabled(Features.highlight)) {
      return null;
    }
    const { counter, min, max, step } = this.state;
    return <div className={cx(showErrorsClassname, styles.componentBlock)}>
      <h1>Counter</h1>
      <h2>step: <SxInput min={1} max={10} type="number" placeholder="step" style={{ width: 70 }} value={min} onChange={this.setStep} /></h2>
      <h2>min: <SxInput min={-10} max={10} type="number" placeholder="min" style={{ width: 70 }} value={min} onChange={this.setMinValue} /></h2>
      <h2>max: <SxInput min={-10} max={10} type="number" placeholder="max" style={{ width: 70 }} value={max} onChange={this.setMaxValue} /></h2>
      <div className={styles.componentGrid}>
        <SxCounter
          step={step}
          min={min}
          max={max}
          counter={counter}
          onCounterUpdate={this.handleCounterUpdate}
        />
      </div>
      <div className={styles.componentGrid}>
        <SxCounter
          min={min}
          max={max}
          counter={5}
          disabled={true}
          onCounterUpdate={this.handleCounterUpdate}
        />
      </div>
    </div>;
  }

  renderSxColorPicker() {
    if (!isFeatureEnabled(Features.highlight)) {
      return null;
    }
    const { color } = this.state;
    return <div className={cx(showErrorsClassname, styles.componentBlock)}>
      <h1>Color picker</h1>
      <div className={styles.componentGrid}>
        <SxColorPicker color={color} colors={[...ChartColorsUiRefresh]} onClick={this.handleChangeColor}/>
      </div>
      <div className={styles.componentGrid}>
        <SxColorPicker disabled color={color} colors={[...ChartColorsUiRefresh]} onClick={this.handleChangeColor}/>
      </div>
    </div>;
  }

  renderRestrictedButton() {
    if (!isFeatureEnabled(Features.plgexternal)) {
      return null;
    }

    return <div className={styles.componentBlock}>
      <h1>Restricted Button</h1>
      <RestrictedButton isLocked disabled label="Restricted" />
    </div>;
  }

  private renderSxTrialModal() {
    const { trialModalOpen } = this.state;
    const header = (
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%' }}>A title</div>
    );
    const leftSection = (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column',
          height: '100%',
          margin: 10,
        }}
      >
        <h1>left section</h1>
        <p>some text...</p>
      </div>
    );
    const list = (
      <ul>
        <li>value</li>
        <li>value</li>
        <li>value</li>
        <li>value</li>
        <li>value</li>
        <li>value</li>
        <li>value</li>
        <li>value</li>
      </ul>
    );
    const rightSection = (
      <div
        style={{
          display: 'flex',
          backgroundColor: '#EAECF0',
          borderRadius: '6px',
          padding: 5,
          margin: '10px 10px 0 0',
        }}
      >
        {list}
      </div>
    );
    const rightSection2 = (
      <div
        style={{
          display: 'flex',
          backgroundColor: '#EAECF0',
          borderRadius: '6px',
          padding: 5,
          margin: 10,
        }}
      >
        {list}
      </div>
    );
    const rightBottomSection = (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          border: '1px solid #EAECF0',
          borderRadius: '6px',
          justifyContent: 'center',
          flexDirection: 'column',
          padding: 20,
          margin: '0 10px 10px 0',
        }}
      >
        <p>some text</p>
        <p>some text</p>
        <p>some text</p>
        <p>some text</p>
        <SxButton featureToUse={Features.uirefresh} type="primary">click me</SxButton>
      </div>
    );
    return (
      <div className={styles.componentBlock}>
        <h1>SxTrialModal</h1>
        <SxButton type="primary" featureToUse={Features.uirefresh} onClick={() => this.handleTrialModalClick(0)}>both sections</SxButton>
        <SxButton type="primary" featureToUse={Features.uirefresh} onClick={() => this.handleTrialModalClick(1)}>one section</SxButton>
        {
          trialModalOpen[0] &&
          <SxTrialModal
            header={header}
            leftSection={leftSection}
            rightSection={rightSection}
            rightBottomSection={rightBottomSection}
            onClose={() => this.handleTrialModalClick(0)}
          />
        }
        {
          trialModalOpen[1] &&
          <SxTrialModal
            header={header}
            leftSection={leftSection}
            rightSection={rightSection2}
            onClose={() => this.handleTrialModalClick(1)}
          />
        }
      </div>
    );
  }

  render() {
    return <div className={styles.content}>
      {this.renderSxButtons()}
      {this.renderSxInputs()}
      {this.renderSxInputNumbers()}
      {this.renderSxInputButtons()}
      {this.renderSxTextArea()}
      {this.renderSxTrialModal()}
      {this.renderSxSelections()}
      {this.renderSxDropdowns()}
      {this.renderSxModals()}
      {this.renderSxModalsFixedWidth()}
      {this.renderSxDeleteModals()}
      {this.renderSxAlerts()}
      {this.renderSxCheckboxes()}
      {this.renderSxCheckboxGroupColumns()}
      {this.renderSxSwitch()}
      {this.renderSxSwitchMultiple()}
      {this.renderSxLoaders()}
      {this.renderSxProgress()}
      {this.renderSxBreadcrumb()}
      {this.renderSxQuill()}
      {this.renderSxIcon()}
      {this.renderTable()}
      {this.renderTooltip()}
      {this.renderSxColorPicker()}
      {this.renderSxCounter()}
      {this.renderRestrictedButton()}
    </div>;
  }
}
